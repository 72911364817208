import { documents as sidDocuments } from '../../../../../../store/modules/securitize-id/options';

const { categories: { documentTypeIndividual, documentTypeIndividualFull } } = sidDocuments;

export default {
  categories: {
    documentTypeIndividual: documentTypeIndividual.filter(doc => doc.value.docCategory === 'identification'),
    documentTypeIndividualFull,
    entityDocumentTypes: [
      { value: { docCategory: 'entity', docType: 'adverse-media' }, text: 'Adverse Media Record' },
      { value: { docCategory: 'signer-entity', docType: 'articles-of-corporation' }, text: 'Articles of Corporation' },
      { value: { docCategory: 'entity', docType: 'articles-of-incorporation' }, text: 'Articles of Incorporation' },
      { value: { docCategory: 'signer-entity', docType: 'articles-of-organization' }, text: 'Articles of Organization' },
      { value: { docCategory: 'entity', docType: 'audited-financials' }, text: 'Audited Financials' },
      { value: { docCategory: 'entity', docType: 'authorized-signers-list' }, text: 'Authorized Signers List' },
      { value: { docCategory: 'entity', docType: 'bank-statement' }, text: 'Bank Statement' },
      { value: { docCategory: 'entity', docType: 'board-resolutions' }, text: 'Board of Resolutions' },
      { value: { docCategory: 'signer-entity', docType: 'by-laws' }, text: 'Executed Bylaws' },
      { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
      { value: { docCategory: 'entity', docType: 'certificate-of-good-standing' }, text: 'Certificate of Good Standing' },
      { value: { docCategory: 'entity', docType: 'employment-letter' }, text: 'Employment Letter' },
      { value: { docCategory: 'entity', docType: 'financial-documents' }, text: 'Financial Documents' },
      { value: { docCategory: 'entity', docType: 'identity-doc' }, text: 'Identity Document' },
      { value: { docCategory: 'entity', docType: 'idology-report' }, text: 'IDology Report' },
      { value: { docCategory: 'entity', docType: 'list-of-officers-and-directors' }, text: 'List of Officers & Directors' },
      { value: { docCategory: 'signer-entity', docType: 'list-of-trustees' }, text: 'List of Trustees' },
      { value: { docCategory: 'signer-entity', docType: 'lp-list-and-authorised-signers-list' }, text: 'LP list and authorized signers list' },
      { value: { docCategory: 'signer-entity', docType: 'members-and-authorised-signers-list' }, text: "Members's list and authorized signers list" },
      { value: { docCategory: 'entity', docType: 'offering-agreement' }, text: 'Offering Memorandum' },
      { value: { docCategory: 'signer-entity', docType: 'operating-agreement' }, text: 'Operating Agreement' },
      { value: { docCategory: 'entity', docType: 'organization-chart' }, text: 'Organizational Chart' },
      { value: { docCategory: 'signer-entity', docType: 'partnership-agreement' }, text: 'Partnership Agreement' },
      { value: { docCategory: 'entity', docType: 'proof-of-address' }, text: 'Proof of Address' },
      { value: { docCategory: 'entity', docType: 'selfie' }, text: 'Selfie' },
      { value: { docCategory: 'signer-entity', docType: 'shareholders-list-and-authorised-signers-list' }, text: "Shareholder's list and authorized signers list" },
      { value: { docCategory: 'entity', docType: 'shareholders-list' }, text: 'Shareholders List' },
      { value: { docCategory: 'entity', docType: 'tax-document' }, text: 'Tax Document' },
      { value: { docCategory: 'signer-entity', docType: 'document-or-certificate-of-trust' }, text: 'Certificate of Trust' },
      { value: { docCategory: 'entity', docType: 'other' }, text: 'Other' },
    ],
    documentsTypeEntity: {
      'revocable-trust': [
        { value: { docCategory: 'signer-entity', docType: 'document-or-certificate-of-trust' }, text: 'Certificate of Trust' },
        { value: { docCategory: 'signer-entity', docType: 'list-of-trustees' }, text: 'List of Trustees' },
      ],
      'irrevocable-trust': [
        { value: { docCategory: 'signer-entity', docType: 'document-or-certificate-of-trust' }, text: 'Certificate of Trust' },
        { value: { docCategory: 'signer-entity', docType: 'list-of-trustees' }, text: 'List of Trustees' },
      ],
      'limited-partnership/general-partnership': [
        { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
        { value: { docCategory: 'signer-entity', docType: 'partnership-agreement' }, text: 'Partnership Agreement' },
        { value: { docCategory: 'signer-entity', docType: 'lp-list-and-authorised-signers-list' }, text: 'LP list and authorized signers list' },
      ],
      llc: [
        { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
        { value: { docCategory: 'signer-entity', docType: 'articles-of-organization' }, text: 'Articles of Organization' },
        { value: { docCategory: 'signer-entity', docType: 'operating-agreement' }, text: 'Operating Agreement' },
        { value: { docCategory: 'signer-entity', docType: 'members-and-authorised-signers-list' }, text: 'Members\'s list and authorized signers list' },
      ],
      corporation: [
        { value: { docCategory: 'signer-entity', docType: 'by-laws' }, text: 'By-laws' },
        { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
        { value: { docCategory: 'signer-entity', docType: 'articles-of-corporation' }, text: 'Articles of Corporation' },
        { value: { docCategory: 'signer-entity', docType: 'shareholders-list-and-authorised-signers-list' }, text: 'Shareholder\'s list and authorized signers list' },
      ],
      other: [],
    },
  },
  fields: [
    { key: 'image', label: 'Thumbnail' },
    { key: 'docType', label: 'Document Type', tdClass: 'align-middle' },
    { key: 'status', label: 'Status', tdClass: 'align-middle' },
    { key: 'createdAt', label: 'Creation Date', tdClass: 'align-middle' },
    {
      key: 'actions', label: '', i18nKey: 'investorDetails.documents.label.table.empty', tdClass: 'text-nowrap d-inline float-right',
    },
  ],
  statuses: [
    {
      text: 'Pending',
      value: 'pending',
    },
    {
      text: 'Verified',
      value: 'verified',
    },
    {
      text: 'Not verified',
      value: 'not-verified',
    },
  ],
};
